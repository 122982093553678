@import url('https://fonts.googleapis.com/css2?family=Antic+Didone&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: "Antic Didone", sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', 
    sans-serif;
}

html, body {
    scroll-behavior: smooth;
    overflow-x: hidden;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

